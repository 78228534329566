.map{
  position: relative;
  height: calc(100vh - 75px);
  &_back_button{
    position: absolute;
    top: -5px;
    left: 190px;
    z-index: 999;
  }
  &_marker_label{
    position: absolute;
    top: 3px;
    left: -25px;
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
    background-color: #2578d7;
    border-radius: 3px;
    padding: 5px;
  }
}