@import "../../sass/partials/colors";

.mainboard {
  //background-color: #f5f5f5;
  //min-height: 100vh;
  &_card {
    width: 250px;
    height: 295px !important;
    box-shadow: 0 0 15px rgba(0,0,0,0.4);
    border: 2px solid #008e97 !important;
    background: linear-gradient(45deg, rgba(0,142,151,0.20780812324929976) 0%, rgba(0,142,151,0.03974089635854339) 100%);
    //background: linear-gradient(145deg, rgba(0,142,151,0.4206932773109243) 0%, rgba(0,142,151,0.08175770308123254) 100%);
    svg, img {
      width: 200px;
      height: 200px;
      margin: 0 auto;
      object-fit: cover;
    }
  }
}