@import "../../sass/partials/mixins";

.main{
  width: 30rem;
  margin: 1.5rem;
  @include respondMax(phone-land){
    width: 100%;
    margin: 10px;
  }
}

.card{

  border: 1px solid black;
  padding: 1rem;
  background-color: #f5f5f5;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  h4{
    padding-bottom: 10px;
    border-bottom: 1px solid black;
  }
  &_body{
    &_list{
      border-bottom: 1px solid black;
      padding: 10px;
    }
  }
}