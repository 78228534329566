@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap");

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  // font-size: 62.5%;
  height: 100%;
  overflow-x: hidden;
}

body {
  font-family: "Nunito", sans-serif !important;
  height: 100%;
  overflow-x: hidden;
}

a {
  text-decoration: none !important;
  color: inherit;
}

// customize bootstrap variables here

@import "~bootstrap/scss/bootstrap.scss";

.sidebar_on {
  margin-left: 0 !important;
}
