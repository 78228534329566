@import "../../sass/partials/mixins";



.row_div{
  .column:nth-of-type(1){
    .card_div{
      h5{
        margin-bottom: 1.5rem;
      }
    }
  }
  .column:nth-of-type(3){
    .card_div{
      h5{
        margin-bottom: 1.5rem;
      }
    }
  }
}

.card_div{
  height: 12rem !important;
  width: 200px;
  @include respondMax(lg){
    width: 180px;
  }
  @include respondMax(md){
    width: 150px;
    height: 10rem !important;
  }
  @include respondMax(sm){
    height: auto !important;
    width: auto !important;
  }

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  //border: none;
  border-radius: 10px !important;
  background-color: rgba(255,255,255, 0.4) !important;
  border: 2px solid #008e97 !important;
  //border-bottom: 3px solid #008e97 !important;
  position: relative;

  &_icon{
    position: absolute;
    top: -32px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(255,255,255, 0.9) !important;
    border-radius: 50%;
    padding: 10px;
    border: 2px solid #008e97 !important;
  }

  h5{
    font-size: 2rem;
    //color: #3d3d3d;
    color: #008e97;
    @include respondMax(md){
      font-size: 1.6rem;
    }
    @include respondMax(phone-land){
      font-size: 1.1rem;
    }
    font-weight: bold;
    //margin-top: 5px;
  }
  p{
    font-size: 1.7rem;
    @include respondMax(lg){
      font-size: 1.6rem;
    }
    @include respondMax(md){
      font-size: 1.3rem;
    }
    @include respondMax(phone-land){
      font-size: 1.1rem;
    }
    font-weight: bold;
    color: #061856;
    margin: 0;
  }


}

.check_out{
  font-size: 12px;
}