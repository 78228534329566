@import "../../sass/partials/mixins";

.page_width{
  width: 30rem;

  @include respondMax(phone-land){
    width: 100%;
  }
}

.submit_button {
  width: 12rem !important;
  //margin: 10px;
}