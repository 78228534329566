@import "../../sass/partials/mixins";

.mini_banner_background{
  background-image: url("../../images/banner_user.webp");
  background-color: #cccccc;
  min-height: 5rem;
  padding: 1rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  margin-bottom: 3rem;
  position: relative;

  &::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.4);
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &_content{
    h1{
      font-size: 3rem;
      @include respondMax(phone-land){
        font-size: 1.8rem;
      }
    }
    z-index: 10;
    a{
      color: orange;
    }
  }
}