@import '../../sass/partials/colors';
@import "../../sass/partials/mixins";

#footer{
  width: 100%;
  background-color: $color-black;
  height: 70px;
  color: $color-white;
  text-align: center;
  padding: 10px;
  //margin-top: 2rem;
  @include respondMax(md) {
    margin-left: 0;
  }
}