.forgot {
  width: 100%;
  height: 100%;
  background-image: url("../../images/bg_image.webp");
  background-color: #f9f9f9;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &_card {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
}
