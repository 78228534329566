@import "../../sass/partials/mixins.scss";

.side_bar {
  width: 230px;
  height: calc(100vh - 60px);
  //min-height: 100vh;
  position: fixed;
  top: 60px;
  margin-left: -300px;
  background-color: #fce1e1;
  //   background-color: #000;
  transition: 0.4s;
  z-index: 100;
  overflow-y: scroll;

  //@include respondMax(md) {
  //  margin-left: -300px;
  //}

  &_profile {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    &_image {
      width: 100px;
      height: 100px;
      background-color: #fff;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .nav-item {
    //color: rgb(140, 36, 27);
    //&:hover {
    //  background-color: #fff;
    //  color: orange !important;
    //}
    a {
      font-size: 1.1rem;
      color: #000;
      &:hover {
        background-color: #fff;
        color: black !important;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
    }
  }
}

.sd_active {
  background-color: rgb(212, 210, 210);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  color: #000 !important;
}
